.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 200ms, visibility 200ms;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.alert {
  background-color: var(--background);
  padding: 16px;
  max-width: 380px;
  width: 100%;
  margin-top: 20vh;
  border-radius: 4px;
  box-shadow: 1px 2px 4px 1px rgb(0 0 0 / 40%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.alert p{
        text-align: center;
        font-weight: 500;
        color: var(--text);
}