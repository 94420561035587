.breadcrumb{
    margin-bottom: 10px;
    border-bottom: 1px solid gainsboro;
}
.breadcrumb__path{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 4px;
}
.breadcrumb__path__chip{
    font-size: 12px;
   padding: 2px 8px;
   background-color: gainsboro;
   border-radius: 4px;
}
.breadcrumb__path__chip:hover{
    background-color: rgb(192, 192, 192);
}

.breadcrumb > * {
    font-weight: 500;
}