.menu{
    width: 280px;
    z-index: 999999;
    right: 0;
    background-color: rgb(250, 250, 250);
    border: 1px solid gainsboro;
    border-radius: 4px;
    position: fixed;
    right: 16px;
    top: 0;
    transition: all 500ms ease-in-out;
    padding: 8px;
    box-shadow: 1px 2px 2px 2px rgb(0 0 0 / 40%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.menu>*{
    width: 100%;
}
.menu>*:hover{
    background-color: rgb(240, 240, 240);
}
