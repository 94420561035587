.mobile_layer{
    display: flex;

    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 40%);
}
.mobile_layer__ham{
    width: 30px;
    background-color: var(--primary);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.search{
    flex: 1;
}

.search__field{
    border: 2px solid var(--primary);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 26px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.search__field input{
    border: none;
    outline: none;
    font-weight: 500;
    color: var(--text);
    padding: 0 8px;
    font-size: 12px;
}
.search__field input:focus{
    border: none;
    outline: none;
    padding: 0 8px;
}

.search__field div{
    width: 16px;
    background-color: var(--primary);
}

.search__field button{
    width: 40px;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__result{
    position: relative;
    top: 0;
    width: calc(100% - 14px);
}
@media all and (max-width:575px){
    .search__field input[type=text] {
        height: 22px;
    }
}

@media screen and (min-width: 481px) {
    .mobile_layer{
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (min-width: 992px) {
    .mobile_layer{
        display: none;
    }
}