.chips, .chips.default{
    font-size: 12px;
    font-weight: 600;
    background: #e0e0e0;
    padding: 4px 8px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    color: rgba(0, 0, 0, 0.87);
    display: inline-grid;
    place-items: center;

}
.chips:hover, .chips.default:hover{
    background: rgb(200, 200, 200);
}
.chips.default:active{
    background: rgb(220, 220, 220);
}
.chips:active{
    box-shadow:  0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
}
/* INFO */
.chips.info{
    background: #673ab7;
    color: #fff;
}
.chips.info:hover{
    background: #51249e;
}
.chips.info:active{
    background: #3a147a;
}
/* POSITIVE */
.chips.positive{
    background: #69f0ae;
}
.chips.positive:hover{
    background: #4bd693;
}
.chips.positive:active{
    background: #27ac6b;
}
/* PRIMARY */
.chips.primary{
    background: var(--primary);
}
.chips.primary:hover{
    background: var(--primary-dark);
}
.chips.primary:active{
    background: var(--primary-deep);
}
/* CALM */
.chips.calm{
    background: #e91e63;
}
.chips.calm:hover{
    background: #dd1658;
}
.chips.calm:active{
    background: #c00c48;
}
/* HARD */
.chips.hard{
    background: #607d8b;
}
.chips.hard:hover{
    background: #7391a0;
}
.chips.hard:active{
    background: #819fad;
}
/* CAUTION */
.chips.caution{
    background: #ffd740;
}
.chips.caution:hover{
    background: #ecc432;
}
.chips.caution:active{
    background: #c9a420;
}
/* SECONDARY */
.chips.secondary{
    background: var(--secondary);
    color: white;
}
.chips.secondary:hover{
    background: var(--secondary-dark);
}
.chips.secondary:active{
    background: var(--secondary-deep);
}
/* WARN */
.chips.warn{
    background: #f44336;
    color: #fff;
}
.chips.warn:hover{
    background: #e2362a;
}
.chips.warn:active{
    background: #c5271c;
}