.categories{
    background-color: #f4f3f3;
    box-shadow: 0 2px 4px -1px #000;
}

@media screen and (max-width: 767px) {
    .categories{
        display: none;
    }
}

