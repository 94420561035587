.category {
  background-color: rgb(250, 250, 250);
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  
}

.catgory__item:hover p{
 color: white;
}
