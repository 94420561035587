.select {
  position: relative;
}

.select label {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: block;
}

.input-box {
  position: relative;
}

.input-box input {
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.125rem;
}

.input-box input:hover {
  border: 1px solid rgb(165, 165, 165);
}

.input-box input:focus {
  border: 1px solid rgba(19, 114, 56, 0.527);
  outline: none;
}

.icon {
  position: absolute;
  right: calc(0.5rem);
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.options {
  position: absolute;
  width: 100%;
  max-height: 192px;
  overflow-y: scroll;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  border-radius: 0.25rem;
  background: #fff;
  z-index: 10;
  overflow-x: hidden;
}

.options.off-focus {
  display: none;
}

.options.on-focus {
  display: block;
}

.options ul {
  list-style-type: none;
}

.options ul li {
  padding: 0.5rem;
  font-size: 1.25rem;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.options ul li:hover {
  background: rgba(0, 0, 0, 0.04);
}

.error {
  font-size: 14px;
  /* font-weight: bold;
  margin-left: 10px;
  min-height: 12px; */
  color: red;
  position: absolute;
}
