.payment{
    width: 100%;
    
}
.payment__row{
    border: 1px solid rgb(238, 238, 238);
}

.payment__row:nth-child(odd){
    background-color: rgba(228, 228, 228, 0.9);
}

.payment__row td{
    font-size: 16px;
    font-weight: 400;
    padding: 4px 8px;
}

.payment__row td:first-child {
    font-weight: 500;
}
.payment__row td:nth-child(2) {
    text-align: right;
}
.payment__row td:nth-child(3) {
    text-align: left;
}