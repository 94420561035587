.second_layer{
    padding: 0 16px 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
}

@media screen and (max-width: 480px) {
    .second_layer{
        padding: 0 4px 4px 4px;
    }
}