/*Button Style*/
a {
    cursor: pointer;
}

.btn_inline_edit {
    float: right;
    margin-top: 15px;
    cursor: pointer;
}

label {
    font-size: 1.3em;
    margin: 10px 0 2px;
}

.updating {
    /* background-image: url("/Content/IqraService/Img/loading_line.gif"); */
}

.nav-top-menu .vertical-menu-content.is-home {
    display: none;
}

.nav-top-menu.home .vertical-menu-content.is-home {
    display: block;
}

.nav-top-menu.nav-ontop .vertical-menu-content.is-home {
    display: none;
}

.nav-top-menu .box-vertical-megamenus:hover .vertical-menu-content.is-home {
    display: block;
}

.SearchFont {
    font-family: Times New Roman, Times, serif;
    font-size: 20px;
}

.search_current_price,
.search_unit_text {
    font-size: 15px;
    white-space: nowrap;
    color: #f96d10;
}

.search_gen_style {
    font-size: 14px;
}

.SearchProductName {
    color: var(--primary);
}

.SearchProductName:hover {
    color: var(--secondary);
}

.SearchDelPrice {
    color: red;
    font-weight: normal;
}

.SearchPrice {
    font-weight: 600;
    line-height: 28px;
    font-family: "Open Sans", sans-serif !important;
    text-transform: capitalize;
}

.SearchProps {
    color: #756c6c;
    font-size: 12px;
    line-height: 15px;
}

.AddtoBag {
    color: white;
    background-color: var(--primary);
    font-weight: 500
}

.AddtoBag:hover {
    opacity: 0.8;
}

.SearchTable {
    background-color: white !important;
    position: absolute;
    z-index: 999999;
    top: 41px;
    min-width: 100%;
}

.UrgentSearchTable {
    width: 100%;
    background-color: white !important;
    position: absolute;
    z-index: 1000;
    top: 32px;
    min-width: 200px;
    font-family: Times New Roman, Times, serif;
    font-size: 15px;
}


.SearchTable tbody tr:hover td,
.SearchTable tbody tr:hover th,
.UrgentSearchTable tbody tr:hover td,
.SearchTable tbody tr:hover th {
    background-color: #d5d5d5;
}



.LoadMoreTitle {
    font-size: 15px;
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
}

.vertical-qty {
    width: 50px !important;
}


.checkoutPage {
    width: 28% !important;
}

.quick-view .heart,
.quick-view .compare,
.quick-view .search {
    padding-top: 10px !important;
}

.right-block h5.product-name {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: inherit;
}


.new-attributes .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: inherit !important;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: inherit !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    text-align: center;
}

.new-attributes .btn {
    border-radius: inherit;
    background: var(--secondary);
    border-color: var(--secondary);
    color: #fff;
}

.new-attributes .btn:hover,
.new-attributes .btn.focus {
    border-radius: inherit;
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: #fff;
}

.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-add-cart.btn-add-cart-lazz {
    border-radius: 5px !important;
    color: var(--secondary) !important;
    padding: 10px 0px !important;
    background-color: transparent;
    line-height: 0px !important;
    border: 2px solid var(--secondary) !important;
    margin-top: 10px !important;
    display: flex !important;
    align-items: center !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
    justify-content: start !important;
    width: 170px;
    margin: inherit;
    height: 40px;
}

.btn-add-cart.btn-add-cart-lazz svg {
    fill: var(--secondary);
}

.btn-add-cart.btn-add-cart-lazz:hover {
    background: var(--secondary);
    color: #fff !important;
}

#radio_button_5,
#radio_button_6 {
    margin-right: 8px;
}

.btn-add-cart.btn-add-cart-lazz:hover svg {
    fill: #fff;
}


.right-block h5.product-name a {
    vertical-align: baseline;
}

.product_des {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: inherit;
    font-size: 12px;
    color: #9b9797;
}

.product_gen {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: inherit;
    color: #f96d10;
}

.CreateAcc {
    color: navy;
}

.cart-box {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1000;
    background: var(--primary);
    border: 1px solid var(--primary);
    border-right-color: rgb(0, 99, 148);
    border-right-style: solid;
    border-right-width: 1px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    border-right: none;
}

.cart-table-wrap>span {
    font-weight: 600;
    padding: 5px 0px;
    display: block;
    font-size: 15px;
}

.car-box-title.SearchFont>span {
    font-weight: 700;
    font-family: "Open Sans", sans-serif !important;
    font-size: 15px;
}

.cart-items {
    background-color: var(--secondary);
    padding: 4px 4px;
    color: #FFF;
    font-weight: bold;
    border-radius: 10px 0 0 0;
}

.cart-amount {
    background-color: #eaeaea;
    color: #777;
    border-radius: 0 0 0 10px;
    padding: 4px 4px;
    font-weight: bold;
}

.cart-box-view {
	z-index: 999999;
	position: fixed;
	right: 0;
	top: 50%;
	max-width: 408px;
	min-width: 30%;
	transform: translateY(-50%);
	box-shadow: 0 0 3px rgba(0,0,0.3);
    overflow-x: hidden;
}

.cart-items.text-center>span {
    font-size: 13px;
}

.cart-amount-span {
    font-size: 13px;
}

.cart-bag.text-center>img {
    width: 50px !important;
    height: auto;
}

.cart-box-inner-view {
    display: block;
    /* max-width: 408px; */
}

.cart-header {
	padding: 5px 0;
	background: var(--secondary);
}

.cart-body {
    background: #ddd;
    border-bottom: 1px solid #000;
    overflow-y: auto;
    height: 408px;
    overflow-x: hidden;
}

.cart-table-wrap {
    border: 1px solid #ececec;
    border-radius: 7.98px;
    background: #fff;
    display: block;
    padding: 30px;
    margin-bottom: 50px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
}

.cart-footer {
    display: flex;
    padding: 10px;
    background: var(--primary);
}

.card-title-product>a>span {
    font-size: 14px;
    transition: 0.6s;
    width: 120px !important;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
}

.card-title-product .SearchDelPrice {
    font-size: 11px !important;
}

.card-title-product .SearchPrice {
    font-size: 11px !important;
    line-height: 14px !important;
}

.SearchFont.SearchPrice {
    font-size: 12px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif !important;
    color: #000;
    line-height: 16px;
}

.dis-new-con .SearchFont.SearchDelPrice aside {
    display: inherit;
    margin-right: 5px;
    font-size: 15px;
}

.dis-new-con .SearchFont.SearchDelPrice {
    font-size: inherit;
    line-height: inherit;
}


.car-box-title {
    color: white;
    margin-left: 5px;
    font-size: 20px;
    margin-top: 5px;
}

.col-xs-8.lazz-cart-item {
    display: flex;
    align-items: center;
}

.cart-table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    font-size: 14px;
}

.cart-table tbody tr td {
    border-bottom: 1px solid #dcdcdc;
    padding: 15px 10px;
    line-height: 1.3;
}

.cart-table td img {
    width: 35px;
    border: 1px solid skyblue;
    border-radius: 5px;
}

.qty-holder {
    display: inline-block;
    width: 90px;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 0;
}

.qty-dec-btn {
    width: 22px;
    display: inline-block;
    width: 30px;
    height: 30px;
    background: var(--secondary);
    border: 1px solid var(--secondary);
    color: #fff;
    line-height: 28px;
    border-radius: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
}

.qty-input {
    width: 22px !important;
    display: inline-block;
    vertical-align: middle;
    width: 35px !important;
    font-size: 13px;
    text-align: center;
    color: #777;
    height: 30px;
    border-radius: 0;
    border: 1px solid var(--secondary);
    margin: 0 -1px;
    outline: none;
}

.qty-inc-btn {
    width: 22px;
    display: inline-block;
    width: 30px;
    height: 30px;
    background: var(--secondary);
    border: 1px solid var(--secondary);
    color: #fff;
    line-height: 28px;
    border-radius: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
}

.cart-cross-btn {
    margin-right: 10px;
    margin-top: 5px;
}


.cart_info_details .cart-box-view {
    display: block;
}



.notice {
    text-align: center;
    /* margin-top: 4%; */
    border-bottom: 1px dashed black;
    border-top: 1px dashed black;
}

.empty_style {
    margin: 0;
    margin-bottom: 30px;
}

.request_table {
    border: 1px solid black;
    width: 70%;
    margin-left: 15%;
}

.request_customer_info {
    width: 90%;
    margin-left: 6%;
}

.btn_upload,
.span {
    background: var(--primary);
    color: white;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: white;
}

.vertical-dropdown-menu {
    width: 900px;
}

.blog_heading_date {
    font-family: Times New Roman, Times, serif;
    font-size: 12px;
    font-weight: 200;
    margin-top: 2%
}

.blog_heading_title {
    font-family: Times New Roman, Times, serif;
    font-size: 20px;
}

.blog_heading_image {
    width: 900px;
    max-height: 400px;
    margin-top: 2%;
}

.blog_content {
    margin-top: 2%;
    line-height: 1.6;
}

.post_title_head {
    font-family: Verdana, SolaimanLipi;
    font-size: 18px;
}

.post-desc h5.post_title_head {
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: inherit;
}

.post-desc h5.post_title_head a {
    vertical-align: baseline;
}

.image .icon_index {
    margin-top: 10%;
}



/*contact form*/
#form_container {
	padding: 15px 15px;
	background-color: #fff;
	color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	overflow: hidden;
	
}



.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.develop_by {
    margin-top: 5%;
}

.develop_by a {
    color: black;
    font-weight: 600;
    font-family: 'Arial', sans-serif;
}

.Method_Selector {
    font-size: 16px;
    font-weight: bold
}

.privacy_note {
    text-align: center;
    margin-top: 6%;
    font-size: 12px;
}

.team_heading_title.text-center>h1 {
    font-size: 35px;
    font-weight: 700;
}


.team_heading_title {
    margin-top: 3%;
    margin-bottom: 3%;
}


.my-card {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 28px;
    border-radius: 15px;
    overflow: hidden;
    transition: .5s;
}

.my-card-body.trainer-card-body center h5 {
    color: #000;
    font-weight: 700;
    line-height: 35px;
    margin-bottom: 6px;
}

.my-card:hover {
    box-shadow: 0px 0px 8px #0066cc
}

.my-card img {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.my-card-body {
    padding: 20px;
    margin-bottom: 20px
}

.my-card-body h5 {
    text-transform: capitalize;
    line-height: 25px;
    font-size: 25px
}

.my-card-body h6 {
    text-align: center;
    font-size: initial;
    color: #04432E;
    font-weight: 600;
}

.my-card-body p {
    margin: 10px 0;
    font-size: medium;
    text-align: justify;
    color: #000;
    line-height: 1.5;
}

/*Comment Area*/
.comment_container .item {
    border: 1px solid #ebedf0;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
}

.comment_container .item .commnet-dettail {
    background-color: #ebedf0;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
    margin-left: 5px;
}

.comment_container .item .row {
    margin: 0;
}

.comment_container .item .row .ctr_img {
    width: 60px;
}

.comment_container .item .row .commnet-dettail_container {
    width: calc(100% - 60px);
    padding-left: 10px;
}


.comment_container .item .row .col {
    float: left;
}

.comment_container .item .comment_event {
    margin-bottom: 10px;
}

.comment_container .item .comment_event a {
    margin-right: 10px;
    cursor: pointer;
    font-size: 0.8em;
}

.comment_container .item .comment_event .active {
    color: var(--secondary);
}

.no_margin {
    margin: 0;
}

.prescription_icon {
    float: left;
    margin: 10px auto 0;
    cursor: pointer;
}

.prescription_icon.selected,
.prescription_icon .selected {
    opacity: 0.3;
    cursor: default;
}

.services2 .services2-item {
    min-height: 90px;
}

.container_vid {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-top: 2%;
    margin-bottom: 2%
}

.responsive_iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.center_order_text {
    margin: auto;
    width: 95%;
    padding: 10px;
    margin-top: 2%
}

.center_order_text h2 {
    margin-bottom: 2%;
}

/*.top-header .nav-top-links,
.top-header .user-info,
.top-header .support-link {
    font-size: .8em;
}*/

/* Animator Css Start*/

.animator {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: white;
}

.btn-add-cart-container .animator {
    background: var(--primary) none repeat scroll 0 0;
    clear: both;
    color: #fff;
}

.btn-add-cart-container .animator:before {
    /* background: rgba(0, 0, 0, 0) url("../images/cart.png") no-repeat scroll left center; */
    content: " ";
    float: left;
    height: 100%;
    margin-right: 15px;
    width: 16px;
}


.nav-top-links a,
.dropdown a {
    color: white;
}

/*Animator CSS End*/

@media (max-width:991px) {
    .Mobile_Menu {
        line-height: 27px;
        color: #fff;
        font-size: 17px;
        font-weight: bold;
    }

    .request_product {
        width: 100%
    }

    .option2 #main-menu .navbar-default .navbar-nav>li>a {
        color: #fff;
        font-size: 14px;
    }

    .column-margin {
        margin-bottom: 2%;
    }

    .box-vertical-megamenus {
        display: none;
    }

    .hide_on_mobile {
        display: none !important;
    }

    .develop_by>li>a>img {
        width: auto;
    }

    .top_header_content .col-sm-6.col-md-6 {
        display: flex;
        justify-content: space-between;
    }

    .new-phon.new-mail::after {
        opacity: 0;
        display: none;
    }

    .info-content>ul>li>a span {
        font-size: 9px;
    }

    .box-vertical-megamenus .vertical-menu-list>li>a {
        width: 220px;
    }

    .navbar nav-menu show-brand {
        display: none;
    }

    .top_header_content {
        text-align: center;
    }

    .search_btn_text {
        display: none;
    }

    .icon-cart {
        padding: 7px;
    }

    .search_gen_style {
        font-size: 11px;
    }
}





.External_link {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2%;
}

.External_Block {
    text-align: center;
    background: var(--primary);
}

.External_Block_Title {
    text-align: center;
    color: white;
    font-size: x-large;
}

.External_Block_Button {
    margin-top: 2%;
    margin-bottom: 2%;
    background: var(--secondary);
    padding: 2%;
    border-radius: 10px;
    color: white;
    letter-spacing: 3px;
}

.view-product-list {
    width: 93%;
    margin: 0 auto;
}

.search_product_image {
    height: 100%;
    max-width: 100%;
}

/*.main-header .header-search-box .form-inline .btn-search.loading {
  background-image: url("/Content/IqraService/Img/Spin-1s-40px (white).gif");
}*/
.request_product input.loading {
    /* background-image: url("/Content/IqraService/Img/Spin-1s-40px.gif"); */
    background-position: 100% center;
    background-repeat: no-repeat;
}

.navbar-header .navbar-toggle {
    float: left;
    margin-left: 15px;
}

.order_info {
    text-align: center;
    margin: 0 auto;
    font-size: 25px;
    color: red;
    font-family: initial;
}

.info_container {
    margin-bottom: 30%;
}


/*--------------------------------*/

/* .section .row {
    margin-bottom: 10%;
} */

/* .section .row .col-md-6 {
        background: #f5f5f5;
        margin-right: -2%;
        padding: 5%;
    } */

.section h3 {
    color: #004085;
}

.career_common {
    margin-top: 10%;
    color: #545b62;
    margin-bottom: 5%;
    font-size: 16px;
}

.section img {
    width: 100%;
}

/*--------------------------------------------*/

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    text-align: center;
}

.timeline-panel {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #04432E;
    border-radius: 5px;
    padding: 30px 0px;
}

.page-header.text-center #timeline {
    font-size: 35px;
    font-weight: 700;
}


.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    padding: 20px;
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

.timeline-footer {
    padding: 20px;
    background-color: #f4f4f4;
}



.timeline>.timeline-badge {
    left: -12px;
}

.job_title {
    color: #2b882f;
    font-weight: bold;
    font-size: 18px;
    margin: 0px 0px 10px 0px;
}

.job_nat>h4 {
    color: #000;
    font-weight: 600;
}

.job_nat>p {
    color: #464847;
}

.job_nat {
    color: #5C5C5C;
    margin: 2%;
}

.contact_info {
    text-align: center;
    margin-top: 5%;
    color: #8a8585;
}


.gallery-title {
    font-size: 36px;
    color: #42B32F;
    text-align: center;
    font-weight: 500;
    margin-bottom: 70px;
}

.gallery-title:after {
    content: "";
    position: absolute;
    width: 7.5%;
    left: 46.5%;
    height: 45px;
    border-bottom: 1px solid #5e5e5e;
}

.filter-button {
    font-size: 18px;
    border: 1px solid #42B32F;
    border-radius: 5px;
    text-align: center;
    color: #42B32F;
    margin-bottom: 30px;
}

.filter-button:hover {
    font-size: 18px;
    border: 1px solid #42B32F;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    background-color: #42B32F;
}

.btn-default:active .filter-button:active {
    background-color: #42B32F;
    color: white;
}

.port-image {
    width: 100%;
}

.gallery_product {
    margin-bottom: 10px;
    height: 300px;
    overflow: hidden;
    padding: 10px;
}

.product-list li .product-price {
    font-size: 19px;
    font-weight: 600;
}

.gallery_image {
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
    margin-bottom: 28px;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
}

.OrderNotice {
    font-weight: 600;
    color: red;
    font-family: Times New Roman, Times, serif;
    font-size: 14px;
}

.top-header {
    background: var(--primary);
    min-height: 40px !important;
    font-size: 1.2em;
    color: white;
}

.top_header_content {
    margin-top: 12px;
    margin-left: 5px;
}

#introduce-box {
    margin-top: 10px;
}

.product-list li .group-price {
    position: absolute;
    top: 15px;
    right: 10px;
    height: auto;
}

.product-list li .group-price .product-new {
    border-radius: 4px;
}



.right-block {
    text-align: center;
}

/*.product-list .main_dispaly_container .main_dispaly {
    border: 1px solid #777;
}*/

.left-block {
    height: 210px;
    border: 1px solid silver;
    border-radius: 7px;
    padding: 5px;
}



.btn_add_to_cart {
    background: transparent;
    border-radius: 5px;
    padding: 6px 20px;
    transition: 0.3s;
    opacity: 1;
    margin: 0px 10px;
    margin-bottom: 0px;
    position: relative;
    border: 1px solid #016449;
    cursor: pointer;
    margin-bottom: 10px;
    display: block;
}

.btn_add_to_cart img {
    margin-top: 4px;
    /*vertical-align: middle;
        max-width: 100%;*/
}

.btn_add_to_cart a:hover {
    color: white;
}

.cross_price {
    color: red;
    font-size: 13px;
    font-weight: 500;
    margin-left: 0.5rem;
}


.product-list .main_dispaly_container .main_dispaly {
    padding: 5px;
    padding-top: 10px;
}

.product-list li .left-block {
    position: relative;
    overflow: hidden;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) {
    .col-sm-2.col-md-2.col-xs-6.main_dispaly_container {
        width: 25%;
    }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 600px) {
    .left-block {
        height: 155px;
    }
}


.product-list li .right-block {
    padding: 0 !important;
}

.search_img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
}

.search_button {
    padding: 5px;
    border: 1px solid #eaeaea;
    background: var(--secondary);
    color: #fff;
    font-size: 17px;
    border-radius: 6px;
}

.main_dispaly:hover .btn_add_to_cart {
    background-color: var(--primary);
}

.product-container:hover .btn_add_to_cart {
    background-color: #016449;
    color: #fff;
}

.tit-contain a,
.dev_com a {
    color: white;
}


@supports not (-webkit-touch-callout: none) {

    /*Notice Area*/
    @keyframes ticker {
        0% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }

    .tcontainer {
        width: 100%;
        overflow: hidden;
    }

    .ticker-wrap {
        width: 100%;
        background-color: var(--secondary);
        height: 50px;
        border: 1px solid var(--primary);
        margin-top: 1px;
    }

    .ticker-move {
        display: inline-block;
        white-space: nowrap;
        /*padding-right: 100%;*/
        padding-left: 100%;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: ticker;
        animation-duration: 50s;
    }

    .ticker-move:hover {
        animation-play-state: paused;
    }

    .ticker-item {
        display: inline-block;
        padding: 0 2rem;
        margin-top: 9px;
        font-size: 22px;
        color: white;
    }

    /*End Notice Area*/
}

.btn.btn-success.btn-default.btn-round.pull-right.CheckButton:hover {
    background: black;
}

@supports (-webkit-touch-callout: none) {

    /*Notice Area*/
    @-webkit-keyframes ticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
        }
    }

    .tcontainer {
        width: 100%;
        overflow: hidden;
    }

    .ticker-wrap {
        width: 100%;
        background-color: var(--secondary);
        height: 50px;
        border: 1px solid var(--primary);
        /*padding-left: 100%;*/
    }

    .ticker-move {
        display: inline-block;
        white-space: nowrap;
    }



    .ticker-item {
        display: inline-block;
        padding: 0 2rem;
        margin-top: 9px;
        font-size: 22px;
        color: white;
    }

    /*End Notice Area*/
}

.in-stock {
    background: var(--primary);
    color: white;
    font-size: 18px;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
}

.out-stock {
    background: #e33030;
    color: white;
    font-size: 18px;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
}

.search_out_stock {
    background: #e33030;
    color: white;
    font-size: 11px;
    padding: 3px;
    border-radius: 4px;
    white-space: nowrap;
}

.search_in_stock {
    background: var(--primary);
    color: white;
    font-size: 11px;
    padding: 3px;
    border-radius: 4px;
    white-space: nowrap;
}


@media all and (max-width:991px) {
    .nav-top-menu.home .vertical-menu-content.is-home {
        display: none;
    }
}