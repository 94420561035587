.mobile_nav{
    position: absolute;
    top: 0;
    right: 100vw;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    transition: right 200ms ease-in-out;
}

.mobile_nav>div{
    min-width: 280px;
    padding: 4px;
    background-color: rgb(240, 240, 240);
    display: inline;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.mobile_nav__link--active .card{
    background-color: gainsboro;
}

.close{
    justify-content: center;
    border: 1px solid var(--warn);
}

.close p{
    color: var(--warn);
}

.close:hover{
    background-color: var(--warn);
    
}
.close:hover p{
    color:rgb(240, 240, 240)
}

@media screen and (min-width: 992px) {
    .mobile_nav{
        display: none;
    }
}