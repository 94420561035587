.table_container{
  overflow-x: auto;
}

.table {
  border: 1px solid gainsboro;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;

  color: #000000de;
}

.table.table--paginate {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

.table thead tr {
  background-color: #dfdfdf;
  vertical-align: middle;
}

.table thead th {
  font-size: 14px;
  font-family: 'roboto';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;

  padding: 10px;
  border: solid 1px gainsboro;
  border-style: none none solid none;
}

.table tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

.table tbody td {
  font-size: 14px;
  font-family: 'roboto';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  padding: 10px;
  border: solid 1px gainsboro;
  border-style: none none solid none;

  vertical-align: middle;
}

.table tbody tr:last-child td {
    border-bottom: none;
  }

.table tfoot td {
  padding: 10px;
  background-color: #dfdfdf;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.table__paginator {
  text-align: right;
  padding: 10px;
  background-color: #dfdfdf;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 0 0 4px 4px;
  border: solid 1px gainsboro;
  border-top: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
.table__paginator__actions{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.table__paginator__actions__nagivator{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: all 200ms ease-in;
}

.table__paginator__actions__nagivator:hover{
  background-color: rgba(255, 255, 255, 0.4);
}
.table__paginator__actions__nagivator:focus{
  background-color: rgba(255, 255, 255, 0.7);
}
.table__paginator__actions__nagivator__arrow path{
  fill: #000000de;
}

/* BUTTONS */
.button{
  font-weight: 500;
  height: 32px;
  width: 100%;
  min-width: 60px;
  padding: 2px 4px;
  border-radius: 4px;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit{
  background-color: var(--primary);
  color: white;
}

.submit:hover{
  background-color: #096131;
}
.submit:focus{
  background-color: #0cb457;
}
.submit:active{
  background-color: #0cb457;
}

input[type=text],
input[type=number]{
  color: var(--text);
  font-weight: 500;
  height: 32px;
  width: 40px;
  padding: 2px 4px;
  border-radius: 4px;
  width: 100%;
}
input[type=text]:focus,
input[type=number]:focus{
  padding: 2px 4px;
  outline: none;
}
