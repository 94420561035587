.trademark a:hover{
    color: #f08321;
}

@media screen and (min-width: 768px) {
    .trademark {
        flex-direction: row;
    }
    .trademark *{
        font-size: 14px;
    }
    .trademark__dev--short-text{
        display: none;
    }
    .trademark__dev--long-text{
        display: inline;
    }
}