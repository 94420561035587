.auto_order{
    padding: 4px 16px;
    border: 1px solid var(--secondary);
    background-color: #2bcb7341;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auto_order label{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    font-family: 'roboto';
}
.auto_order input {
    width: 16px;
    margin: 0;
}