.auth{
    position: fixed;
    width: 100vw;
    height: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.auth_dialouge{
    margin-top: 10vw;
    width: 50%;
    max-width: 480px;
    min-width: 320px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 30%);
}

.auth_dialouge__header{
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
}

.auth_dialouge__header h4{
    font-size: 20px;
    cursor: pointer;
}

.auth_dialouge__header i{
    font-size: 20px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.auth_dialouge__header i:hover{
    color: red;
}

.auth_dialouge__body{
    padding: 16px;
}

.auth_dialouge__form_field{
    margin-bottom: 24px;
    position: relative;
}

.auth_dialouge__form_field label{
    margin: 0;
    font-weight: 500;
    width: 100%;
    color: var(--text);
}

.auth_dialouge__form_field input{
    border-radius: 4px;
    padding: 6px 8px;
}

.auth_dialouge__form_field input:focus{
    padding: 6px 8px;
    outline: none;
    border: 1px solid rgba(165, 165, 165);
}

.auth_dialouge__form_field input:hover{
    border: 1px solid rgba(165, 165, 165, 0.616);
}

.auth_dialouge__form_field small{
    position: absolute;
    top: 100%;
    color: rgb(199, 55, 0);
}

.auth_dialouge__error{
    margin-bottom: 5px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth_dialouge__error p{
    color: rgb(199, 55, 0);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.auth_dialouge__error p .info{
    color: var(--primary) !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.auth_dialouge__actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.auth_dialouge__actions p{
    font-size: 14px;
}

.auth_dialouge__actions__button{
    padding: 8px 22px !important;
    background-color: var(--secondary);
    color: white;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 1px var(--primary);
}

.auth_dialouge__actions__button--text{
    color: var(--primary);
    cursor: pointer;
}
.auth_dialouge__actions__button--text:hover{
    text-decoration: underline;
}
.auth_dialouge__actions__button--text:active{
    color: var(--secondary);
}
.auth_dialouge__actions__button:hover{
    box-shadow: 1px 1px 3px 1px var(--primary);
}
.auth_dialouge__actions__button:active{
    box-shadow: none;
    background-color: var(--primary);
}

@media all and (max-width: 575px) {
    .auth_dialouge{
        width: 80% !important;
        max-width: 80%;
    }
    .auth_dialouge__actions{
        flex-direction: column;
    }
}


