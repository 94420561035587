/*------------------------------------------------------------------
[Table of contents]
1. Styles for devices(>1200px)
2. Styles for devices(>=992px and <=1199px)
3. Styles for devices(>=768px and <=992px)
4. Styles for devices(>=481px and <=767px)
5. Styles for devices(<=480px)
-------------------------------------------------------------------*/
/*----------------
[1. Styles for devices(>1200px)]
*/

@media (min-width: 1694px) and (max-width: 4800px) {
  #view-product-list ul {
      grid-template-columns: repeat(auto-fill, 199px) !important;
  }
}
@media (min-width: 992px) and (max-width: 1419px) {
  #view-product-list ul {
      grid-template-columns: repeat(auto-fill, 210px) !important;
  }
}


@media (min-width: 992px) and (max-width: 1299px) {
  main {
    min-height: auto !important;
  }
}

@media (min-width: 1201px) and (max-width: 1299px) {
  body {
    font-size: 12px !important;
  }
  .info-content > ul > li > a {
    padding: 3px 13px !important;
  }
}



@media (min-width: 1056px) and (max-width: 1199px) {
  .info-content > ul > li > a {
    padding: 6px 13px !important;
  }
}




@media (min-width: 992px) and (max-width: 4800px) {
  .newCatagory {
    display: none !important;
    box-shadow: 0px 3px 7px rgb(0 0 0 / 20%);
  }
}

/*----------------
[2. Styles for devices(>=993px and <=1200px)]
*/
@media (min-width: 993px) and (max-width: 1200px) {
  .result-card__details--actions button strong {
    font-size: 10px !important;
  }
  .info-content > ul > li > a {
    padding: 3px 13px !important;
  }
  .info-content > ul > li {
    height: 42px;
  }
  .info-content ul > li > a svg {
    height: 2.5rem !important;
    padding: 5px 0px;
  }
  .info-content > ul > li > a > span {
    font-size: 13px !important;
  }
  .nav-menu .nav > li > a {
    padding: 15px 15px;
  }

  .info-content {
    margin-right: 3px;
}


  .main-header .shopping-cart-box {
    float: right;
    width: 25%;
  }

  .box-vertical-megamenus .vertical-menu-content {
    display: none;
  }

  .home .box-vertical-megamenus .vertical-menu-content {
    display: block;
  }

  #home-slider .slider-left {
    display: none;
  }

  #home-slider .header-top-right {
    width: 100%;
    margin: 0;
    border-top: none;
    padding: 0 15px;
  }

  #home-slider .header-top-right .homeslider,
  #home-slider .header-top-right .header-banner {
    border-top: 3px solid #04432e;
  }

  .service .service-item {
    padding: 0;
    overflow: hidden;
  }

  .service .service-item .icon {
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
    height: auto;
  }

  .service .service-item .info {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .product-list li .product-star {
    float: left;
  }

  .latest-deals .count-down-time span {
    font-size: 12px;
  }

  .product-list.grid li {
    width: 50%;
  }

  .subcategories li a {
    padding: 0 10px;
  }

  #left_column .block .block_content {
    padding: 10px;
  }

  #left_column .block .title_block {
    font-size: 14px;
    padding-left: 10px;
  }

  .layered .layered_subtitle {
    font-size: 14px;
  }

  .check-box-list input[type="checkbox"] + label span.button {
    margin-right: 5px;
  }

  .special-product .special-product-left {
    width: 100%;
  }

  .special-product .special-product-right {
    margin-left: 0;
    margin-top: 10px;
  }

  .sortPagiBar .sort-product {
    margin-top: 20px;
  }

  .product-tab .nav-tab > li > a {
    padding: 0 10px;
  }

  #product .pb-right-column .product-comments .comments-advices a {
    padding: 0 5px;
  }

  /** MAIN MENU **/
  #main-menu .navbar .navbar-nav > li > a {
    padding: 0 10px;
  }

  .hot-deals-box .hot-deals-tab-content-col {
    padding-left: 15px;
  }

  .hot-deals-box .hot-deals-tab-content {
    padding: 30px;
  }

  .box-products .box-product-content .box-product-adv {
    width: 20%;
    padding-right: 5px;
  }

  .box-products .box-product-content .box-product-list {
    width: 80%;
    margin: 0;
    float: left;
    padding-left: 5px;
  }

  .box-products .box-product-content .box-product-list .product-list li {
    padding-bottom: 5px;
  }
}

/*--------------------
[3. Styles for devices(>=768px and <=992px)]
*/
@media (min-width: 768px) and (max-width: 992px) {
  .row.custom-bonik {
    display: flex;
  }

  #contact-box {
    margin-top: 30px;
  }

  .row.custom-slide-row {
    display: inherit !important;
  }

  .home .box-vertical-megamenus .vertical-menu-content {
    display: none;
  }

  .vertical-megamenus-ontop .box-vertical-megamenus .vertical-menu-content {
    display: block;
  }

  .top-header a {
    border-right: 1px solid #e0e0e0;
    padding-right: 5px;
    margin-left: 5px;
  }

  .col-sm-12.col-right-tab.col-right-tabxxx {
    width: 100% !important;
  }

  .main-header .header-search-box {
    padding-left: 30px;
    padding-right: 15px;
    width: 100%;
  }

  .main-header .shopping-cart-box {
    margin-left: 0;
    width: 37.667%;
  }

  .box-vertical-megamenus {
    padding-bottom: 0;
  }

  .box-vertical-megamenus .vertical-menu-content {
    min-width: 270px;
    display: none;
  }

  .nav-menu .nav > li > a {
    padding: 15px 5px;
  }

  #home-slider .slider-left {
    height: 3px;
  }

  .header-top-right .header-banner {
    display: none;
  }

  .header-top-right {
    margin-left: 0;
    padding: 0 15px;
    width: 100%;
    border-top: none;
  }

  .header-top-right .homeslider .content-slide {
    margin-right: 0;
  }

  .service .service-item {
    padding: 0 10px;
  }

  .service .service-item .icon {
    width: 100%;
    text-align: center;
  }

  .service .service-item .info {
    width: 100%;
    padding-left: 0;
    text-align: center;
    margin-left: 0;
    margin-top: 50px;
  }

  .page-top .page-top-left {
    width: 100%;
  }

  .page-top .page-top-right {
    width: 100%;
    margin-top: 30px;
  }

  #trademark-list #payment-methods {
    width: 100%;
    float: left;
  }

  .category-banner {
    display: none;
  }

  .floor-elevator {
    display: none;
  }

  .show-brand .navbar-brand {
    padding: 0px 0px 0px 10px;
  }

  .header-top-right .homeslider {
    width: 100%;
  }

  .product-featured .banner-featured {
    display: none;
  }

  .product-featured .product-featured-content .product-featured-list {
    margin-left: 0;
  }

  .product-list.grid li {
    width: 50%;
  }

  .subcategories li a {
    padding: 0 10px;
  }

  #left_column .block .block_content {
    padding: 10px;
  }

  #left_column .block .title_block {
    font-size: 14px;
    padding-left: 10px;
  }

  .layered .layered_subtitle {
    font-size: 14px;
  }

  .check-box-list input[type="checkbox"] + label span.button {
    margin-right: 5px;
  }

  .special-product .special-product-left {
    width: 100%;
  }

  .special-product .special-product-right {
    margin-left: 0;
    margin-top: 10px;
  }

  .sortPagiBar .sort-product {
    margin-top: 20px;
  }

  .trademark-info {
    width: 100%;
    float: left;
    padding: 0 20px;
  }

  .trademark-product {
    width: 100%;
    float: left;
    padding: 0 20px;
  }

  .trademark-product .product-item {
    width: 50%;
  }

  .product-list li .product-star {
    float: left;
  }

  .product-tab .nav-tab > li {
    width: 100%;
    float: left;
    margin-bottom: 2px;
  }

  .product-tab .nav-tab > li > a {
    width: 100%;
  }

  .product-tab .nav-tab > li.active > a {
    height: 48px;
  }

  .product-tab .tab-container {
    margin-top: 2px;
  }

  #product .pb-right-column .product-comments .comments-advices a {
    padding: 0 5px;
  }

  .products-block .products-block-left {
    width: 100%;
  }

  .products-block .products-block-right {
    margin: 0;
    width: 100%;
  }

  /** MAIN MENU **/
  #main-menu .navbar .navbar-nav > li > a {
    padding: 0 12px;
  }

  .box-products .box-product-content .box-product-adv {
    display: none;
  }

  .box-products .box-product-content .box-product-list {
    width: 100%;
    margin: 0;
  }

  .hot-deals-box .hot-deals-tab-content-col {
    padding-left: 15px;
  }

  .hot-deals-box .hot-deals-tab-content {
    padding: 30px;
  }
}

/*--------------------
[4. Styles for devices(>=481px and <=767px)]
*/
@media (min-width: 481px) and (max-width: 767px) {
  .home .box-vertical-megamenus .vertical-menu-content {
    display: none;
  }

  .category-featured > .nav-menu > .container {
    padding-left: 0;
  }

  .top-banner {
    display: none;
  }

  .top-header .nav-top-links,
  .top-header .user-info,
  .top-header .support-link {
    float: left;
  }

  .main-header .logo {
    text-align: center;
  }

  .main-header .header-search-box {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .main-header .header-search-box .form-inline .form-category {
    display: none;
  }

  .main-header .header-search-box .form-inline .input-serach {
    width: calc(100% - 50px);
  }

  .main-header .shopping-cart-box {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    width: 100%;
  }

  #box-vertical-megamenus {
    width: 50%;
    height: 50px;
    float: left;
  }

  #box-vertical-megamenus .box-vertical-megamenus {
    right: 0px;
    padding-bottom: 0;
  }

  .box-vertical-megamenus .vertical-menu-content {
    border-right: 1px solid #eee;
    display: none;
  }

  .box-vertical-megamenus
    .vertical-menu-content
    ul
    li:hover
    .vertical-dropdown-menu {
    visibility: hidden;
    display: none;
  }

  .box-vertical-megamenus .vertical-menu-content ul li a.parent:before {
    display: none;
  }

  .popular-tabs .nav-tab li {
    padding: 0;
  }

  #home-slider .header-banner {
    display: none;
  }

  #home-slider .header-top-right {
    padding-right: 15px;
    padding-left: 15px;
    margin: 0;
    border-top: none;
  }

  .header-top-right .homeslider {
    width: 100%;
  }

  #home-slider .header-top-right .homeslider .content-slide {
    margin-right: 0;
  }

  #home-slider .slider-left {
    height: 3px;
  }

  .service {
    display: none;
  }

  .nav-menu .navbar-brand,
  .nav-menu .toggle-menu {
    display: block;
  }

  .nav-menu .navbar-collapse {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    margin-right: 0;
    margin-left: 0;
    padding: 0 15px;
  }

  .nav-menu .navbar-collapse {
    margin-top: 0;
  }

  .nav-menu .nav > li > a {
    padding: 5px 15px;
  }

  .nav-menu .navbar-brand {
    padding-left: 30px;
  }

  .popular-tabs .nav-tab li {
    width: 100%;
    float: left;
  }


  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .floor-elevator {
    display: none;
  }

  .category-banner {
    display: none;
  }

  .product-featured .banner-featured {
    display: none;
  }

  .product-featured .product-featured-content .product-featured-list {
    margin-left: 0;
  }

  .banner-bottom {
    display: none;
  }

  .trademark-info {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .trademark-product .image-product {
    width: 40%;
  }

  .trademark-product .info-product {
    padding-left: 10px;
  }

  .page-top-right {
    margin-top: 30px;
  }

  .main-header .shopping-cart-box:hover .cart-block {
    opacity: 0;
    visibility: hidden;
  }

  .product-featured .product-featured-content {
    width: 100%;
  }

  .popular-tabs .owl-controls {
    top: -15px;
  }

  .category-slider {
    margin-top: 30px;
  }

  .subcategories li a {
    padding: 0 10px;
  }

  .popular-tabs .owl-controls .owl-next,
  .popular-tabs .owl-controls .owl-prev {
    top: -15px;
  }


  .center_column {
    margin-top: 30px;
  }

  #product .pb-right-column {
    margin-top: 30px;
  }

  .product-tab .nav-tab > li {
    width: 100%;
    float: left;
    margin-bottom: 2px;
  }

  .product-tab .nav-tab > li > a {
    width: 100%;
  }

  .product-tab .nav-tab > li.active > a {
    height: 48px;
  }

  .product-tab .tab-container {
    margin-top: 2px;
  }

  .product-list li .product-star {
    float: left;
  }

  .breadcrumb {
    line-height: 20px;
  }


  /** MAIN MENU **/
  #main-menu .navbar-header {
    display: block;
    margin: 0;
    background: #04432e;
    color: #fff;
    margin-left: 15px;
  }

  #main-menu .navbar-header .navbar-brand {
    padding: 0;
    padding-left: 10px;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  #main-menu .navbar-header .fa {
    line-height: inherit;
    color: #fff;
    font-size: 17px;
  }

  #main-menu .navbar-header .navbar-toggle {
    border: none;
    padding: 0;
    margin-top: 12px;
  }

  #main-menu .navbar-default .navbar-toggle:focus,
  #main-menu .navbar-default .navbar-toggle:hover {
    background: none;
  }


  #main-menu .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  #main-menu .navbar-collapse.in {
    overflow-y: inherit;
  }

  #main-menu .navbar .navbar-nav > li {
    border-bottom: 1px solid #cacaca;
  }

  #main-menu .navbar .navbar-nav > li:hover,
  #main-menu .navbar .navbar-nav > li.active {
    background: none;
  }

  #main-menu .navbar .navbar-nav > li > a {
    margin: 0;
    padding: 10px;
    border-right: none;
    position: relative;
  }

  #main-menu .navbar .navbar-nav > li:hover > a,
  #main-menu .navbar .navbar-nav > li.active > a {
    color: #333;
    border-right: 1px solid transparent;
  }

  #main-menu .dropdown-menu {
    position: inherit;
    opacity: 1;
    visibility: inherit;
    display: none;
  }

  #main-menu li.dropdown > a:after {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  #main-menu .dropdown.open > .dropdown-menu {
    display: block;
  }

  #main-menu li.dropdown:before {
    display: none;
  }

  #main-menu li.dropdown:hover:before {
    display: none;
  }

  #main-menu .navbar-nav > li > .mega_dropdown {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 100% !important;
    left: 0 !important;
    top: 0;
    padding: 15px 0;
    border: 1px solid #eee;
  }

  #main-menu .dropdown-menu {
    border: 1px solid #eee;
  }

  #main-menu .dropdown-menu.container-fluid {
    padding: 15px;
    border: 1px solid #eee;
  }

  #footer2 .footer-top .footer-menu li {
    display: block;
    width: 100%;
    line-height: 30px;
  }

  #footer2 .footer-top .footer-social {
    margin-top: 20px;
    float: left;
  }

  #footer2 .footer-row .form-subscribe {
    width: auto;
  }

  #footer2 .footer-row .form-subscribe .form-group {
    margin-bottom: 0;
  }

  #footer2 .footer-row {
    padding: 20px 0;
  }

  #footer2 .widget-body {
    padding-bottom: 20px;
  }

  #footer2 .widget-title {
    padding-bottom: 10px;
  }

  #footer2 .footer-bottom .footer-payment-logo {
    float: left;
    margin-top: 10px;
  }

  .hot-deals-box .hot-deals-tab .hot-deals-tab-box {
    padding: 10px;
  }

  .hot-deals-box .hot-deals-tab-content-col {
    padding-left: 15px;
  }

  .hot-deals-box .hot-deals-tab-content {
    padding: 10px;
  }

  .box-products .box-product-head {
    float: left;
    height: auto;
    width: 100%;
  }

  .box-products .box-tabs {
    float: left;
    margin-top: 10px;
  }

  .box-products .box-tabs > li {
    display: block;
    width: 100%;
  }

  .box-products .box-tabs li > a {
    border: none;
    padding: 0;
  }

  .box-products .box-product-head .box-title {
    padding-left: 0;
    width: 100%;
    float: left;
  }

  .box-products .box-tabs li > a:before {
    left: 0;
    right: 0;
  }

  .box-products .box-product-content .box-product-adv {
    display: none;
  }

  .box-products .box-product-content .box-product-list {
    margin-left: 0;
    float: left;
    overflow: hidden;
    width: 100%;
  }
}

/*--------------------
[5. Styles for devices(<=480px)]
*/
@media (max-width: 480px) {
  .top-banner {
    display: none;
  }
  .result-card__details--actions button strong {
    font-size: 10px !important;
  }

  .top-header .nav-top-links,
  .top-header .user-info,
  .top-header .support-link {
    float: left;
  }

  .main-header .logo {
    text-align: center;
    margin-top: 10px;
  }

  .main-header .header-search-box {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }

  .main-header .header-search-box .form-inline .form-category {
    display: none;
  }

  .main-header .header-search-box .form-inline .input-serach {
    width: calc(100% - 50px);
  }

  .main-header .shopping-cart-box {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    width: 100%;
    margin-top: 30px;
  }

  .nav-top-menu {
    background: transparent;
  }

  #box-vertical-megamenus {
    width: 100%;
    height: 50px;
    float: left;
    background: #04432e;
  }

  .option2 .box-vertical-megamenus .title {
    background: #04432e !important;
  }

  .row.custom-bonik {
    display: flex;
  }

  .title-menu > i {
    position: relative;
    top: 16px;
    cursor: pointer;
  }

  .option2 #footer {
    margin-top: 35px !important;
  }

  #box-vertical-megamenus .box-vertical-megamenus {
    right: 0px;
    padding-bottom: 0;
  }

  .box-vertical-megamenus .vertical-menu-content {
    border-right: 1px solid #eee;
    display: none;
  }

  .box-vertical-megamenus .title {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
  }

  #box-vertical-megamenus .vertical-menu-content {
    min-width: 290px;
    display: none;
  }

  .box-vertical-megamenus
    .vertical-menu-content
    ul
    li:hover
    .vertical-dropdown-menu {
    visibility: hidden;
    display: none;
  }

  .box-vertical-megamenus .vertical-menu-content ul li a.parent:before {
    display: none;
  }

  .popular-tabs .nav-tab li {
    padding: 0;
  }

  #home-slider .header-banner {
    display: none;
  }

  #home-slider .header-top-right {
    padding-right: 15px;
    padding-left: 15px;
    margin: 0;
    border-top: none;
  }

  .header-top-right .homeslider {
    width: 100%;
  }

  #home-slider .header-top-right .homeslider .content-slide {
    margin-right: 0;
  }

  #home-slider .slider-left {
    height: 3px;
  }

  .service {
    display: none;
  }

  .nav-menu .navbar-brand,
  .nav-menu .toggle-menu {
    display: block;
  }

  .nav-menu .navbar-collapse {
    position: absolute;
    right: 0px;
    top: 50px;
    margin-right: 0;
    margin-left: 0;
    padding: 0 15px;
    left: 0;
    right: 0;
  }

  .nav-menu .navbar-collapse > ul {
    margin-top: 0;
  }

  .nav-menu .navbar-brand {
    margin-left: -10px;
  }

  .popular-tabs .nav-tab li {
    width: 100%;
    float: left;
  }

  .page-top-right {
    margin-top: 30px;
  }

  .content-page {
    margin-top: 0;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .floor-elevator {
    display: none;
  }

  .category-banner {
    display: none;
  }

  .product-featured .banner-featured {
    display: none;
  }

  .product-featured .product-featured-content .product-featured-list {
    margin-left: 0;
  }

  .product-featured .product-featured-content {
    width: 100%;
  }

  .banner-bottom {
    display: none;
  }

  .trademark-info {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .trademark-product .image-product {
    width: 40%;
  }

  .trademark-product .info-product {
    padding-left: 10px;
  }

  #introduce-box {
    margin-top: 30px;
  }

  #introduce-box .introduce-title {
    margin-top: 15px;
  }

  #trademark-list #payment-methods {
    display: block;
    width: 100%;
  }

  .product-list li .product-star {
    float: left;
    width: 100%;
    text-align: left;
  }

  .product-list li .content_price {
    float: left;
    width: 100%;
  }

  .latest-deals {
    padding-bottom: 15px;
  }

  .main-header .shopping-cart-box:hover .cart-block {
    opacity: 0;
    visibility: hidden;
  }

  .category-featured > .nav-menu > .container {
    padding-left: 0;
  }

  .category-slider {
    margin-top: 30px;
  }

  .category-slider .owl-controls .owl-prev,
  .category-slider .owl-controls .owl-next {
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    padding-top: 3px;
  }

  .subcategories li.current-categorie {
    width: 100%;
  }

  .subcategories li.current-categorie a {
    width: 100%;
    text-align: center;
  }

  .subcategories li.current-categorie:after {
    right: 50%;
    content: "\f0d7";
    top: 36px;
  }

  .subcategories li {
    width: 100%;
  }

  .subcategories li a {
    width: 100%;
    padding: 0 10px;
  }

  .sortPagiBar .sort-product,
  .sortPagiBar .show-product-item {
    margin-top: 20px;
  }

  .product-list.list .left-block {
    width: 100%;
    position: relative;
  }

  .product-list.list .right-block {
    width: 100%;
  }

  .product-list.list .add-to-cart {
    bottom: 0;
    left: 0;
    width: 50%;
    top: inherit;
  }

  .product-list.list .quick-view {
    bottom: 0;
    right: 0;
    top: inherit;
  }

  .nav-menu .nav > li > a {
    padding: 5px 15px;
  }

  .popular-tabs .owl-controls .owl-next,
  .popular-tabs .owl-controls .owl-prev {
    top: -15px;
  }

  .center_column {
    margin-top: 30px;
  }

  #product .pb-right-column {
    margin-top: 30px;
  }

  .product-tab .nav-tab > li > a {
    padding: 0 10px;
  }

  .product-list li .product-star {
    float: left;
  }

  .product-tab .nav-tab > li {
    width: 100%;
    float: left;
    margin-bottom: 2px;
  }

  .product-tab .nav-tab > li > a {
    width: 100%;
  }

  .product-tab .nav-tab > li.active > a {
    height: 48px;
  }

  .product-tab .tab-container {
    margin-top: 2px;
  }

  #product .pb-right-column .product-comments .comments-advices a {
    width: 100%;
    float: left;
    padding: 0;
  }

  .breadcrumb {
    line-height: 20px;
  }



  /** MAIN MENU **/
  #main-menu .navbar-header {
    display: block;
    margin: 0;
    background: #04432e;
    color: #fff;
    margin-left: 15px;
  }

  #main-menu .navbar-header .navbar-brand {
    padding: 0;
    padding-left: 10px;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  #main-menu .navbar-header .fa {
    line-height: inherit;
    color: #fff;
    font-size: 17px;
  }

  #main-menu .navbar-header .navbar-toggle {
    border: none;
    padding: 0;
    margin-top: 12px;
  }

  #main-menu .navbar-default .navbar-toggle:focus,
  #main-menu .navbar-default .navbar-toggle:hover {
    background: none;
  }

  #main-menu .navbar-collapse {
    padding: 0 15px;
    margin-left: 15px;
    margin-right: 0px;
    background: #000;
    position: absolute;
    top: 45px;
    width: 100%;
    z-index: 9;
  }

  #main-menu .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  #main-menu .navbar-collapse.in {
    overflow-y: inherit;
  }

  #main-menu .navbar .navbar-nav > li {
    border-bottom: 1px solid #cacaca;
  }

  #main-menu .navbar .navbar-nav > li:hover,
  #main-menu .navbar .navbar-nav > li.active {
    background: none;
  }

  #main-menu .navbar .navbar-nav > li > a {
    margin: 0;
    padding: 10px;
    border-right: none;
    position: relative;
  }

  #main-menu .navbar .navbar-nav > li:hover > a,
  #main-menu .navbar .navbar-nav > li.active > a {
    color: #333;
    border-right: 1px solid transparent;
  }

  #main-menu .dropdown-menu {
    position: inherit;
    opacity: 1;
    visibility: inherit;
    display: none;
  }

  #main-menu li.dropdown > a:after {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  #main-menu .dropdown.open > .dropdown-menu {
    display: block;
  }

  #main-menu li.dropdown:before {
    display: none;
  }

  #main-menu li.dropdown:hover:before {
    display: none;
  }

  #main-menu .navbar-nav > li > .mega_dropdown {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 100% !important;
    left: 0 !important;
    top: 0;
    padding: 15px 0;
    border: 1px solid #eee;
  }

  #main-menu .dropdown-menu {
    border: 1px solid #eee;
  }

  #main-menu .dropdown-menu.container-fluid {
    padding: 15px;
    border: 1px solid #eee;
  }

  .page-heading {
    font-size: 16px;
  }

  .page-heading span {
    padding: 0;
  }

  .page-order ul.step li {
    width: 100%;
    display: block;
    text-align: left;
    border-bottom: none;
    border-left: 3px solid #eee;
    padding-left: 20px;
    margin-top: 10px;
  }

  .page-order ul.step li.current-step {
    border-left: 3px solid #04432e;
    border-bottom: none;
  }

  #footer2 .footer-top .footer-menu li {
    display: block;
    width: 100%;
    line-height: 30px;
  }

  #footer2 .footer-top .footer-social {
    margin-top: 20px;
    float: left;
  }

  #footer2 .footer-row .form-subscribe {
    width: auto;
  }

  #footer2 .footer-row .form-subscribe .form-group {
    margin-bottom: 0;
  }

  #footer2 .footer-row {
    padding: 20px 0;
  }

  #footer2 .widget-body {
    padding-bottom: 20px;
  }

  #footer2 .widget-title {
    padding-bottom: 10px;
  }

  #footer2 .footer-bottom .footer-payment-logo {
    float: left;
    margin-top: 10px;
  }

  .box-products .box-product-head {
    float: left;
    height: auto;
  }

  .box-products .box-product-head .box-title {
    float: left;
    width: 100%;
  }

  .box-products .box-tabs {
    float: left;
    margin-top: 10px;
  }

  .box-products .box-tabs > li {
    display: block;
    width: 100%;
  }

  .box-products .box-tabs li > a {
    border: none;
    padding: 0;
  }

  .box-products .box-product-head .box-title {
    padding-left: 0;
  }

  .box-products .box-tabs li > a:before {
    left: 0;
    right: 0;
  }

  .box-products .box-product-content .box-product-adv {
    display: none;
  }

  .box-products .box-product-content .box-product-list {
    margin-left: 0;
    float: left;
    overflow: hidden;
    width: 100%;
  }

  .hot-deals-box .hot-deals-tab .hot-deals-tab-box {
    padding: 10px;
  }

  .hot-deals-box .hot-deals-tab-content-col {
    padding-left: 15px;
  }

  .hot-deals-box .hot-deals-tab-content {
    padding: 10px;
  }
}
@media all and (max-width: 991px) {
.parentFooter {
  grid-template-columns: 1fr 1fr !important;
}
.footerSingle.custom-content-box {
  text-align: left !important;
}
.newCatagory {
  display: block !important;
  box-shadow: 0px 3px 7px rgb(0 0 0 / 20%);
}

}


@media all and (max-width: 767px) {
  
  .parentFooter {
    grid-template-columns: 1fr !important;
}
  .shaping-address-saveing-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
    gap: 1.25rem;
    padding: 5px !important;
  }
  .chosse-your-fvt-btn ul {
    flex-direction: column;
  }
  .chosse-your-fvt-btn ul li {
    width: 100%;
  }
  .cart-box-inner-view {
    max-width: 360px !important;
    min-width: 300px;
  }
  .cart-box-view {
    top: 50% !important;
  }
  .cart-table tbody tr td {
    padding: 14px 5px !important;
  }

  .add-to-cart > span {
    font-size: 12px !important;
  }
  .modal__content {
    margin-top: 0px !important;
  }

  .nav-mobile-buttons.prescription-order {
    display: flex !important;
  }

  .nav-mobile-buttons.prescription-order .info-content ul > li > a i {
    font-size: 15px;
    color: white;
    padding: 3px;
    margin-right: 4px;
  }

  .row.custom-row {
    flex-direction: column;
  }

  .row.custom-row .col-xs-12.col-sm-4.col-lg-3.logo a img {
    width: 165px !important;
  }

  .info-content > ul > li > a span {
    font-size: 14px !important;
  }


  .nav_top_category_container-mobile {
    display: block;
  }

  .tcontainerxxx {
    padding-top: 13px;
    padding-bottom: 30px;
  }

  .btn_cart .btn_add_to_cart {
    padding: 6px 10px !important;
  }

  .col-sm-3.col-lg-2 .info-content {
    display: none;
  }

  .logo > a > img {
    width: 90% !important;
    height: auto !important;
  }

  .main-header .header-search-box .form-inline {
    margin-right: 0px !important;
  }

  .title-menu {
    font-size: 17px;
    font-weight: bold;
  }

  .col-xs-12.col-sm-4.col-lg-3.logo {
    display: flex;
    justify-content: flex-start;
  }

  .d-flex-res.j-c-sb {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .d-flex.dekstop {
    display: none;
  }

  .mobile-nav-links .d-flex {
    display: flex !important;
  }

  .mobile-nav-links > ul > li > a > i {
    font-size: 13px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
  }

  .nav-mid {
    width: 100%;
  }

  .input-group-buttons.product_qty {
    position: absolute;
    top: 95%;
  }
}
@media (max-width: 1140px) {
  .address-info-from {
    flex: 0 0 100% !important;
  }
  .address-info-right-default {
    flex: 0 0 100% !important;
    margin-top: 25px;
  }

  .image_previewer_container {
    flex-direction: column;
  }
}
@media all and (max-width:1130px){

  .gallery_product {
    height: 200px !important;
  }

  .navbar-toggle {
    display: block;
  }
  .navbar-header {
    display: block;
  }
  #main-menu .navbar-header {
    display: contents;
    margin: 0;
    background: #04432e;
    color: #fff;
    margin-left: 15px;
  }
  .nav-mobile-buttons.prescription-order {
    display: flex !important;
    position: absolute;
    right: 110px;
  }
  #main-menu .navbar-header .navbar-toggle {
    border: none;
    padding: 0;
    margin-top: 12px;
    display: none;
  }
  .nav.navbar-nav {
    display: block;
    padding-left: 15px;
  }

  .nav-mobile-buttons.prescription-order .info-content ul > li > a i {
    font-size: 15px;
    color: white;
    padding: 3px;
    margin-right: 4px;
  }

  .row.custom-row {
    flex-direction: column;
  }

  .row.custom-row .col-xs-12.col-sm-4.col-lg-3.logo a img {
    width: 220px !important;
  }

  .info-content > ul > li > a span {
    font-size: 14px !important;
  }


  .nav_top_category_container-mobile {
    display: block;
  }

  .col-sm-3.col-lg-2 .info-content {
    display: none;
  }
  .cart-box-inner-view {
    min-width: 340px;
  }

  .logo > a > img {
    width: 90% !important;
    height: auto !important;
  }

  .col-xs-12.col-sm-4.col-lg-3.logo {
    display: flex;
    justify-content: flex-start;
  }

  .d-flex-res.j-c-sb {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .d-flex.dekstop {
    display: none;
  }

  .mobile-nav-links .d-flex {
    display: flex !important;
  }
  .nav-mid {
    width: 100%;
  }
  .row.custom-row {
      position: relative;
  }
  .banner_image_category_vertical {
      padding-top: 20px;
  }
  footer#footer {
    padding-top: 30px !important;
      margin-top: 30px !important;
  }
  .cart_navigation {
    margin-top: 0px !important;
}
.address-info-from {
  padding-bottom: 15px;
}

.auto-order-container {
  padding-bottom: 15px;
}

  .cart-body {
    height: 250px !important;
  }
  .product-comments-block-tab .new_comment_container > row > .col-md-8 > input {
    margin-bottom: 10px !important;
  }
  .product-comments-block-tab {
    padding: 0px 15px;
  }
  main {
    min-height: auto !important;
  }

  .contact_info {
    margin-top: 15px !important;
  }
  #form_container {
    margin: 30px;
  }
  /* .add-to-cart.d-flex.al-center.j-center {
    width: 85% !important;
  } */

  .order-id > span {
    font-size: 13px !important;
  }
  .group-of-buttons > li .button-order {
    font-size: 12px !important;
  }
  .upload-button-text-container p {
    font-size: 13px;
    margin-top: 7px;
  }
  .upload-button-text-container {
    align-items: center !important;
  }
  .upload-button-text-container > div {
    font-size: 12px;
    flex: 0 0 auto;
  }

  .edit-profile-main-form.first-column {
    flex: 0 0 100%;
  }
  .edit-profile-main-form.second-column {
    flex: 0 0 100%;
    margin-top: 15px;
  } 

  .main-header .header-search-box {
      margin-top: 15px !important;
  }
  .col-md-3.col-sm-6.custom-content-box {
      display: flex !important;
  }

  .develop_by {
    order: -1 !important;
    padding-right: 40px !important;
}

#contact-box {
  margin-top: 22px !important;
}


li.prescriptionMobile > a > span {
  font-size: 10px;
  color: #fff;
}

li.prescriptionMobile {
  background: var(--primary);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 6px;
}

li.prescriptionMobile > a > svg {
  width: 12px;
  fill: #fff;
  margin-right: 6px;
}
li.prescriptionMobile > a {
  display: flex;
}

/* div#root .home.option2.homeServicePadding {
  padding-top: 204px !important;
} */

#columns {
  padding-top: 16px;
  padding-bottom: 0px;
}
.new_msg {
  margin-bottom: 0px !important;
}

/* .result-card .d-flex .add-to-cart.d-flex.al-center.j-center {
  width: auto !important;
} */
.imageContainer {
  width: 35px !important;
}

.tableHeading {
	width: 1%;
}

span.monami-button__text {
  font-size: 11px;
}

.product-desc-review-information-main
  .page-content-order
  .niiceeTabBtn
  .orderbtn {
    font-size: 13px !important;
    padding: 5px 6px !important;
}

.Steps_details__1CSho > h5 {
  font-size: 13px !important;
}
.order-invoice-ea .table th, .order-invoice-ea .table td {
  line-height: 17px !important;
  font-size: 13px !important;
}
.order-invoice-ea .cask-rewarded span {
  font-size: 18px !important;
}
.inv-flex-content {
  padding: 8px 8px !important;
}
.order-invoice-ea .cask-rewarded p {
  font-size: 13px !important;
}
.order-invoice-ea .cask-rewarded h5 {
  font-size: 18px !important;
}

.table-responsive-overflow table tr {
  font-size: 10px;
}

.career_common {
  margin-top: 2% !important;
  margin-bottom: 4% !important;
  font-size: 15px !important;
}
.col-md-6.career-content h1 {
  font-size: 25px !important;
}
.col-md-6.career-img {
  margin-top: 30px;
}
.page-header.text-center.newHeaderCareer {
  margin-top: 0;
  margin-bottom: 0;
}
.page-header.text-center.newHeaderCareer h1 {
  font-size: 25px !important;
}
.row.careerRowMain {
	width: 90%;
	margin: 0 auto;
}
.col-md-6.career-content {
  padding-right: 15px !important;
}


.catagoryFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 99%;
  margin: 0 auto;
}

.childItem a span {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 15px;
  min-height: 28px;
  font-weight: 700;
}

.childItem img {
  width: 22px;
}
.childItem {
  margin: 3px 5px;
  flex: 0 0 96px;
  text-align: center;
  margin: 0 auto;
}


 li.prescriptionMobile > a > span {
  font-size: 10px;
  color: #fff;
}

li.prescriptionMobile {
  background: var(--primary);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 10px;
}

li.prescriptionMobile > a > svg {
  width: 12px;
  fill: #fff;
  margin-right: 6px;
}
li.prescriptionMobile > a {
  display: flex;
}
small.imageContainer.mobileimageContainer {
  text-align: center;
  margin: 0 auto;
  border: 1px solid #ddd;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  background: #ffff;
  flex: 0 0 75px;
  height: 105px !important;
}

.imageContainer {
  width: 110px !important;
}


}

@media all and (max-width:767px){
  .d-flex.mobile-action .add-to-cart {
      padding: 2px 2px;
  }
  .d-flex.mobile-action  .add-to-cart > span {
    font-size: 9px !important;
}
.d-flex.mobile-action .add-to-cart svg {
  fill: var(--primary);
  width: 16px;
}

.d-flex.mobile-action .qty-dec-btn2 {
  padding-left: 1px;
  padding-right: 8px;
}

.d-flex.mobile-action .qty-inc-btn2 {
  padding-right: 1px;
  padding-left: 8px;
}

.d-flex.mobile-action .qty-holder2 .qty-dec-btn2 {
  right: -3px;
}
.d-flex.mobile-action .qty-holder2 .qty-inc-btn2 {
  left: -3px;
  position: relative;
}
.search-result__items .wishlist-btn {
  margin-top: 0px !important;
}
.add-to-cart{
  margin: 5px;
}


.search-result .search-result__items .wishlist-btn .add-tocart-overlay {
  width: 83px !important;
  position: relative;
  height: 34px !important;
}



.d-flex.mobile-action .qty-holder2 > aside input {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  border: 1px solid var(--primary);
  background: var(--primary);
  padding: 0px 0px;
  overflow: hidden;
  height: 34px;
}


  .submit-compline-main-flex .custom-input > textarea {
    height: 100px !important;
}

  .shopbyCatagory > a {
    display: none;
}
  .row.custom-bonik {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .row.custom-bonik .col-sm-6 {
    padding: 0px 30px;
  }

  .pagination > li > a, .pagination > li > span {
      padding: 6px 7px;
  }
  .menuText {
    font-size: 13px;
}

  /* div#root .home.option2.homeServicePadding {
    padding-top: 118px !important;
} */

  .paginator-pagination {
      padding-top: 1rem;
  }

  .branchLocation {
    font-size: 20px !important;
  }

  div#body_content .content-page {
    margin-top: 0px !important;
}

  .cash-back-offer-inner-card {
      padding: 0px 15px;
  }

  .info-content > ul > li > a > span {
      display: none;
  }
  .info-content > ul > li {
    margin-right: 5px !important;
}
  .info-content > ul > li > a {
    font-size: 13px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 50% !important;
}
.nav-mobile-buttons.prescription-order .info-content ul > li > a i {
  font-size: 13px;
  padding: 0;
  margin-right: 0;
  position: relative;
  left: -1px;
}
.nav-mobile-buttons.prescription-order {
  right: 82px;
  top: -2px;
}
.row.custom-row .col-xs-12.col-sm-4.col-lg-3.logo a img {
  width: 200px !important;
}
#main-menu .navbar-header .navbar-toggle {
  display: block !important;
}
.nav_top_category_container-desktop {
  display: none;
}

#main-menu .navbar-header {
  display: none;
}


.nav.navbar-nav {
  position: fixed !important;
  width: 50%;
  background: var(--primary);
  height: 100%;
  left: -100%;
  top: 0;
  z-index: 99;
  opacity: 0;
  transition: 0.6s;
  padding-top: 30px;
  margin: 0px;
}

.nav_icon span {
  color: #fff;
  font-size: 25px;
  position: absolute;
  right: 15px;
  z-index: 999;
  top: 0;
  display: block !important;
}

.navbar-collapse.showMenu .nav.navbar-nav {
 
  left: 0%;
  opacity: 1;
  transition: 0.6s;
}

 button.nav_icon {
  position: absolute;
  z-index: 99;
  right: 20px;
  color: #fff;
  font-size: 20px !important;
  top: 2px;
  display: block !important;
}
#main-menu .navbar .navbar-nav > li {
  border-bottom: none;
}
.cart-body {
  height: 200px !important;
}

.row.gms-row > .col-lg-3 {
	width: 100% !important;
}

.profile-sidebar-inner::after {
	height: 90% !important;
	top: 113px !important;
}


#main-menu {
  background: transparent !important;
  background: transparent !important;
}




.catagoryFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 99%;
  margin: 0 auto;
}

.childItem a span {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 15px;
  min-height: 28px;
  font-weight: 700;
}

.childItem img {
  width: 22px;
}
.childItem {
  margin: 3px 5px;
  flex: 0 0 96px;
  text-align: center;
  margin: 0 auto;
}


 li.prescriptionMobile > a > span {
  font-size: 10px;
  color: #fff;
}

li.prescriptionMobile {
  background: var(--primary);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 10px;
}

li.prescriptionMobile > a > svg {
  width: 12px;
  fill: #fff;
  margin-right: 6px;
}
li.prescriptionMobile > a {
  display: flex;
}

.box-vertical-megamenus .parent {
  font-size: 11px !important;
}
.homeCatagory .splide__slide {
  padding: 2px 3px !important;
}
#box-vertical-megamenus {
  height: 0px !important;
  background: transparent !important;
}
#box-vertical-megamenus .box-vertical-megamenus .vertical-menu-content {
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%) !important;
}
.columns-container {
  margin-top: -5px !important;
}

.imageContainer {
  width: 100% !important;
}

#header .homeCatagory {
  display: none !important;
}

small.imageContainer.mobileimageContainer {
  text-align: center;
  margin: 0 auto;
  border: 1px solid #ddd;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  background: #ffff;
  flex: 0 0 75px;
  height: 105px !important;
}


.col-sm-6.linksFooter1 {
  margin-left: -55px;
}
.col-sm-6.linksFooter2 {
  margin-right: -40px;
}

button.nav_icon {
	z-index: 9;
	left: 9px;
	color: #fff;
	font-size: 18px;
	top: -42px;
	background: var(--primary);
	width: 40px;
	height: 35px;
	margin-left: 20px;
	border-radius: 5px;
}

#main-menu button.nav_icon > i {
  position: relative;
  left: 0px;
  top: 2px;
}

.main-header .header-search-box .form-inline {
	width: 95%;
	margin: 0 auto;
}
.shopbyCatagory .common-heading h1 {
  font-size: 20px !important;
  padding-top: 0px !important;
}
.shopbyCatagory .common-heading {
  height: inherit;
}
.form-control.new-msg {
  height: 120px !important;
}

.status-order-card {
  width: 90px;
  padding-left: 15px;
}
.item-container__image-preview img {
  height: auto !important;
}



}

@media all and (max-width: 575px) {
  .order-dsc.display-flex.jc-s-b span {
      font-size: 12px;
      margin-right: 8px;
  }
  .order-dsc.display-flex.jc-s-b aside {
      font-size: 12px;
  }

  .profile-order-tab .display-flex.jc-s-b {
      padding: 3px 4px !important;
  }
  span.shipping-hide {
    display: none;
}


  .profile-order-left {
    flex: 0 0 50% !important;
  }
  .profile-order-right {
    flex: 0 0 50% !important;
  }

  .develop_by {
    order: -1 !important;
    padding-right: 0px !important;
}
  .linksFooter2 {
    flex: 0 0 auto !important;
}
  .col-md-6.career-content h1 {
    font-size: 20px !important;
}

  .container_prescription ul > li {
    font-size: 14px !important;
}

  /* section.profile-area {
    margin-top: -38px;
  } */

  .mobile-nav-links li.prescriptionMobile {
    margin-right: 6x !important;
  }
  .banner-area .splide__arrow--prev {
    left: 1em !important;
}

.parent img {
  height: 60px !important;
  object-fit: contain !important;
}

  .main-header .header-search-box .form-inline {
    width: 100%;
  }
  /* div#root .home.option2.homeServicePadding {
    padding-top: 145px !important;
} */
.columns-container {
  margin-top: -35px !important;
}

.new-phon {
	font-size: 12px !important;
}
.top_header_content p {
  font-size: 13px !important;
}
/* 
  .banner-area {
    margin-top: -35px !important;
  } */


  .form-inline .pull-right.btn-search > i {
    font-size: 14px !important;
}
.main-header .header-search-box .form-inline .input-serach input {
  height: 30px !important;
}
.main-header .header-search-box .form-inline {
  height: 35px !important;
}
.header-new .main-header .header-search-box .form-inline .btn-search {
  height: 33px !important;
}

.main-header .header-search-box {
  margin-top: 10px !important;
}



  .nav-mobile-buttons.prescription-order .info-content {
      margin-top: 10px !important;
  }
  .row.custom-row nav.nav-mid .mobile-nav-links {
      margin-top: 8px !important;
  }

  #view-product-list ul {
    grid-template-columns: repeat(auto-fill, 180px) !important;
  }
  .row.custom-row .col-xs-12.col-sm-4.col-lg-3.logo a img {
    width: 170px !important;
  }
  #header .main-header .header-search-box {
      padding-left: 80px !important;
  }
  button.nav_icon {
    z-index: 9;
    left: 9px;
    color: #fff;
    font-size: 18px;
    top: -42px;
    background: var(--primary);
    width: 40px;
    height: 35px;
    margin-left: 20px;
    border-radius: 5px;
}

a.cross_time {
  position: relative;
  right: 12px;
  top: -6px;
}

.breadcrumb {
  padding-bottom: 10px;
}
.page-content.page-order {
  margin-top: 0px;
}

.page-order .heading-counter {
  margin: 15px 0;
  padding: 6px;
}



.banner-slider ul.splide__pagination {
  bottom: 0px !important;
}
.spices-auto-scroll ul.splide__pagination {
  display: none;
}

.product_scroll_container {
  padding-top: 20px !important;
}

#main-menu button.nav_icon > i {
  position: relative;
  left: 0px;
  top: 2px;
}

ul.products-block.best-sell >a {
  margin-bottom: 20px;
  display: block;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
}

.mobile-pay .col-md-4.box-border .Method_Selector > input {
  top: -2px !important;
}

.product-tab .nav-tab {
  display: flex;
}

.navbar {
  min-height: 3px;
}
/* main.home.option2 {
  padding-top: 151px !important;
} */
#header .main-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.product_scroll_container .common-heading h1 {
  padding-top: 20px;
  position: relative;
  font-size: 20px !important;
}


.banner_image_category_vertical {
  padding-top: 8px;
}
.product-main-area {
  padding-bottom: 15px !important;
}
b.new-int-title {
  font-size: 14px !important;
}
.newFooter .introduce-title {
  margin-top: 10px !important;
  font-size: 14px !important;
}
.newFooter .dev_com a {
  font-size: 14px;
}
.newFooter .dev_com {
  margin-top: 0;
}
ul#trademark-list {
  flex-direction: column;
}
.product-top-area .product-img {
  width: 100% !important;
}

.result-card__details--name {
  font-size: 10px !important;
  line-height: 14px;
  display: inline-block;
}
.result-card__img {
	width: 56px !important;
}
#header .main-header .header-search-box .form-inline .input-serach input {
	font-size: 11px !important;
}
#introduce-box {
	display: flex;
	flex-direction: column;
}

.request_product {
	padding: 10px 10px !important;
}
.empty_style {
	grid-template-columns: repeat(1, 1fr) !important;
	gap: 5px !important;
}
.Validation > div {
	width: 100%;
}
.validation > div > button {
	margin-top: 15px;
}
.terms-condition-checked > label{
  font-size: 0.8em !important;
}
.request_product .SearchFont.SearchPrice {
	font-size: 13px;
	padding: 5px;
}
.team_heading_title.text-center > h1 {
	font-size: 20px !important;
}
.my-card-body h5 {
	font-size: 20px !important;
}

.profile-order-tab .display-flex.jc-s-b {
	flex-flow: column;
}
.profile-order-tab-new .display-flex.jc-s-b {
	flex-flow: inherit;
}


.group-of-buttons {
	align-items: flex-start !important;
}

.profile-order-tab {
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border: 1px solid var(--secondary);
  border-radius: 5px;
  overflow: hidden;
}

.order-id > span {
	font-size: 15px !important;
	padding: 3px 0px;
}
.popup {
	width: 90%;
}
.custom-table-row {
	overflow-y: scroll;
}
.grid-3.mb-16.g-8 {
	grid-template-columns: repeat(1, 1fr) !important;
}
.group-complain_type {
	grid-template-columns: repeat(1, 1fr) !important;
}
.container-file-button {
	flex-wrap: wrap !important;
  gap: 0px !important;
}
.container-file-button .custom-input {
	flex: 0 0 100% !important;
}

.inv-flex-content.d-flex.js-center.al-center {
	flex-direction: column !important;
	padding: 15px 4px !important;
}
.order-details-buttons.d-flex.js-center.al-center {
  flex-wrap: wrap !important;
  justify-content: start !important;
  align-items: flex-start !important;
}
.order-details-buttons.d-flex.js-center.al-center > li {
  margin: 4px 1px !important;
}
.order-details-buttons > li div {
  font-size: 12px !important;
}


.order-details-buttons.d-flex.js-center.al-center > li {
	margin: 6px 0px;
	padding-right: 0;
}
.profile-sidebar-inner::after {
	top: 190px !important;
}
.file_uploader {
	width: 100% !important;
	max-width: 100% !important;
}

.page-content.page-order .step {
	display: flex;
  flex-wrap: wrap;
}
.page-content.page-order .step .checkoutPage {
	flex: 0 0 33% !important;
}

li.checkoutPage > span {
  font-size: 13px;
}

.address-info-right-default {
  display: none;
}

#columns {
  padding-bottom: 20px;
}

.page-order ul.step li.current-step {
  border-bottom: 3px solid #04432e !important;
  border-left: 0px !important;
}

.page-order ul.step li {
  border-bottom: 3px solid #eee !important;
  border-left: 0px !important;
}

.cart_navigation {
	flex-direction: column !important;
  margin-top: 20px;
}

table.table.table-bordered.table-responsive.cart_summary tr th {
  font-size: 12px;
  width: 90px !important;
}

.banner-area .container-fluid {
  padding-right: 0px !important;
  padding-left: 0 !important;
}

.banner-slider {
  margin-left: 0px !important;
}

.product_heading_info h3 {
  font-size: 13px !important;
}
.product_heading_info span {
  font-size: 13px !important;
}
.mobile-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
label.Method_Selector {
  font-size: 14px !important;
}
.page-order .product-name {
  font-size: 12px;
}


/* profile update css */
.profile-short-desc {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
}

.profile-nav > ul {
  display: flex;
  flex-wrap: wrap;
}
.profile-right {
  text-align: end;
}

.profile-nav > ul li a i {
  display: none;
}

.profile-nav > ul li:hover a {
  padding-left: 10px !important;
}

.profile-sidebar-inner .profile-nav > ul li a.active {
  background: var(--primary) !important;
}

.profile-sidebar-inner::after {
  display: none !important;
}

div#niiceeTab nav.niiceeTabBtn > a > button {
  font-size: 12px;
  padding: 8px 8px;

}

.order-invoice-ea .table th, .order-invoice-ea .table td {
  font-size: 12px !important;
}
.order-invoice-ea .custom-row-top address h2 {
  font-size: 15px !important;
}
.order-invoice-ea address {
  font-size: 13px !important;
}
.custom-row-top .span4 {
  flex: 0 0 200px !important;
}
.custom-row-top .invoice-head tbody tr {
  font-size: 13px !important;
}

.order-invoice-ea .cask-rewarded h5 {
  font-size: 14px !important;
}
.order-invoice-ea .cask-rewarded p {
  font-size: 12px !important;
}
.order-invoice-ea .cask-rewarded span {
  font-size: 14px !important;
}

.new_msg {
  margin-bottom: 0px !important;
}
.msg-blow {
  font-size: 15px !important;
}
.contact_info > span {
  font-size: 18px !important;
}
.col-sm-12.contact_info > h4 {
  font-size: 16px !important;
}
.contact_info > a {
  font-size: 14px !important;
}
.contact_info {
  margin-top: 6px !important;
}

.pb-right-column-new .pd_dtls.add-to-cart.d-flex.al-center.j-center {
  width: 40% !important;
  margin-left: 0;
  margin-top: 7px;
}
.mobile-nav-links {
  margin-right: 7px;
}
#product .pb-left-column .product-image .product-full {
  min-height: auto;

}

.shaping-address-saveing-row {
  justify-content: space-between;
  align-items: center !important;
  gap: 1.25rem;
  flex-direction: row !important;
  margin-bottom: 15px !important;
}
.saving-address-content > span {
  font-size: 13px !important;
}

.file_uploader > label {
  margin-top: 0 !important;
  font-size: 14px !important;
}

.prescription_order_section {
  margin-top: 0px !important;
}
.page-order .cart_navigation a.prev-btn {
  font-size: 12px !important;
}
.page-order .cart_navigation a.next-btn {
  font-size: 12px !important;
}
.cart_navigation {
  flex-direction: row !important;
}
th.unit_price {
  width: 50px !important;
  display: block;
  border-left: 0px !important;
  border-right: 0px !important;
}

.saving-ad-btn > button {
  font-size: 14px;
}

.page-order .cart_navigation a {
  padding: 10px 10px !important;
}

div#address-box > a img {
  width: 75%;
}

.PaymentOption_payment-option__9c6id input {
  width: 14px;
}




.nav_top_category_container-desktop {
  display: block !important;
}



.row.custom-row {
  box-shadow: 0px 5px 8px rgb(0 0 0 / 20%);
  padding-bottom: 5px;
}

#main-menu .navbar {
  border: none;
  margin: 0;
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
}

.common-heading.lazzPollyHeading h1 {
  font-size: 20px !important;
}

.parentFooter {
  padding: 0px 10px !important;
}
.footerSingle.custom-content-box {
  display: flex !important;
  justify-content: space-between !important;
}
.newFooter {
  padding: 0px 14px !important;
}

.profile-nav > ul li a {
  font-size: 12px !important;
  background: #ddd !important;
    margin: 2px !important;
}

.product-desc-review-information-main .page-content-order nav.niiceeTabBtn a.orderBtn > button {
  margin: 4px !important;
}

ul.group-of-buttons .prescription-mobile-hide{
  display: none !important;
}

.profile-order-left .order-dsc.display-flex.jc-s-b {
  justify-content: inherit;
}
.profile-order-right .order-dsc.display-flex.jc-s-b {
  justify-content: inherit;
}

.cash-back-offer-inner-card h1 {
  font-size: 20px;
}

.edit-profile-main-flex {
  padding-top: 10px !important;
}
.container-file-button {
  margin-top: 5px !important;
}
img.w-max.mobile-res {
  position: relative;
  top: 2px;
}

}

@media all and (max-width: 413px) {

  div#niiceeTab nav.niiceeTabBtn > a > button {
      padding: 8px 7px;
  }
  .product-desc-review-information-main .page-content-order nav.niiceeTabBtn a.orderBtn > button {
    margin: 1px !important;
}
.order-dsc.display-flex.jc-s-b aside {
  font-size: 11px;
}
.order-dsc.display-flex.jc-s-b span {
  font-size: 11px;
}

  .order-invoice-ea .cask-rewarded span {
    font-size: 13px !important;
}
  .terms-condition-checked {
    margin: 0 !important;
}
  .catagoryFlex {
    width: 96%;
    margin: 0 auto;
  }
  .col-sm-6.linksFooter1 {
    margin-left: -20px;
  }
  .col-sm-6.linksFooter2 {
    margin-right: -15px;
  }

  .childItem {
      margin: 3px 5px;
      flex: 0 0 80px;
      text-align: center;
  }
  .new-phon {
    font-size: 12px !important;
  }

  


  .group-of-buttons {
    flex-wrap: wrap !important;
  }
  .pb-right-column-new .pd_dtls.add-to-cart.d-flex.al-center.j-center {
      width: 50% !important;
  }

  .inv-flex-content.d-flex.js-center.al-center {
    padding: 5px !important;
}
.order-details-buttons > li div {
  font-size: 10px !important;
}
li.checkoutPage > span {
  font-size: 12px;
}

a.result-card__details--name span {
  display: inline-block;
}

small.imageContainer.mobileimageContainer {
  height: 80px !important;
}

}

@media all and (max-width:374px){
  .new-phon {
    font-size: 11px !important;
}
  .introduce-title {
    font-size: 15px !important;
}
  .result-card__img {
      width: 40px !important;
  }
  .childItem {
      flex: 0 0 92px;
  }
  .row.custom-row .col-xs-12.col-sm-4.col-lg-3.logo a img {
    width: 110px !important;
  }
  .add-to-cart > span {
      font-size: 11px !important;
      margin-left: 1px !important;
  }
  .product_heading_info h3 {
      font-size: 12px !important;
  }
  b.new-int-title {
      font-size: 12px !important;
  }
  .newFooter .dev_com a {
      font-size: 12px;
  }
  .cart-box-inner-view {
      min-width: 300px;
  }

  .page-order .cart_navigation a {
    padding: 10px 6px !important;
}
.page-order .cart_navigation a.prev-btn {
  font-size: 11px !important;
}
.page-order .cart_navigation a.next-btn {
  font-size: 11px !important;
}

.childItem .parent img {
  width: 80% !important;
}
.order-dsc.display-flex.jc-s-b aside {
  font-size: 9px;
}
.order-dsc.display-flex.jc-s-b span {
  font-size: 9px;
}


}





