.noticebar{
    background-color: var(--secondary);
}

.marquee{
    height: 45px;
}

.noticebar__text{
    font-size: 20px;
    font-weight: 500;
    color: white;
    cursor: default;
}