.backDrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 1;
	z-index: 1000;
	visibility: visible;
	background-color: rgba(0, 0, 0, 0.95);
	-webkit-transition: opacity 200ms, visibility 200ms;
	-o-transition: opacity 200ms, visibility 200ms;
	transition: opacity 200ms, visibility 200ms;
}

.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 99;
  }

  .modal.open {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
         -o-transition-delay: 0s;
            transition-delay: 0s;
  }
  .modal-bg {
   position: absolute;
   background: rgba(0,0,0,0.1);
   width: 100%;
   height: 100%;
  }
  .modal-container {
   border-radius: 10px;
   background: #fff;
   position: relative;
   padding: 30px;
   max-width: 550px;
   width: 100%;
   text-align: center;
  }
  .modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    outline: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: red;
    background: none;
    border: 0px;
    font-weight: bold;
    cursor: pointer;
    font-size: 26px;
  }
  
  
  
  .modal-container > img {
   width: 50px;
   height: auto;
   -webkit-animation: tastanimation 2s linear 0.5s alternate 1;
           animation: tastanimation 2s linear 0.5s alternate 1;
   position: relative;
  }

  .request-btn{
        padding: 8px 16px;
        background-color: var(--secondary);
        color: white;
        border-radius: 4px;
        box-shadow: 2px 2px 3px 1px var(--primary);
  }

  .request-btn:hover{
        box-shadow: 1px 1px 3px 1px var(--primary);
  }
  .request-btn:active{
        box-shadow: none;
        background-color: var(--primary);
  }
  @-webkit-keyframes tastanimation {
   0%{
     opacity: 0;
     -webkit-transform: scale(0.8);
             transform: scale(0.8);
     
   }
   50%{
     opacity: 0;
     -webkit-transform: scale(0.9);
             transform: scale(0.9);
     
   }
   100%{
     opacity: 1;
     -webkit-transform: scale(1);
             transform: scale(1);
     
   }
  }
  
  @keyframes tastanimation {
   0%{
     opacity: 0;
     -webkit-transform: scale(0.8);
             transform: scale(0.8);
     
   }
   50%{
     opacity: 0;
     -webkit-transform: scale(0.9);
             transform: scale(0.9);
     
   }
   100%{
     opacity: 1;
     -webkit-transform: scale(1);
             transform: scale(1);
     
   }
  }
  
  .modal-container > h1 {
   font-size: 19px;
   margin: 12px 0px;
   color: #000;
   font-family: var(--poppins);
   font-weight: 500;
  }
  
  .popup-img {
   width: 80px !important;
   margin-top: 10px;
  }
  
  .book_table_item > span {
   font-weight: 500;
   display: block;
   margin-bottom: 5px;
  }