.paginator-pagination{
  display: flex;
  justify-content: center;
  /*padding-top: 2rem;*/
}
.pagination{
  margin: 5px 0;
}
.paginator-pagination ul{
  display: flex;
  justify-content: center;
}

.paginator-pagination .page-item > a{
  color: var(--primary);
  padding: 4px 8px;
  border-left: 1px solid var(--primary);
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
}
.paginator-pagination .page-item:last-child > a{
  border-right: 1px solid var(--primary);
}

.paginator-pagination .page-item.active > a{
  color: white;
  padding: 4px 8px;
  background-color: var(--primary);
}

.paginator-pagination .page-item.disabled > a{
  background-color: gainsboro;
  color: gray;
  cursor: not-allowed;
}