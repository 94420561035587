.checkout{
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 32px;
    background-color: rgb(250, 250, 250);
}

.grid__span__2{
    grid-column: 1/3;
}

@media all and (max-width: 1110px) {
    .checkout{
        grid-template-columns: 1fr;
    }
    .grid__span__2{
        grid-column: 1/1;
    }
    
}