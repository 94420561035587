.navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    background-color: var(--primary);
    padding: 0 16px;
    box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 40%);
}

.navbar__links{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar__link{
    display: block;
}

.navbar__link:hover{
    background-color: var(--secondary);
    border-radius: 4px;
}

.navbar__link h4{
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 16px;
}

.navbar__link--active{
    text-decoration: underline;
    color: white;
}

@media screen and (max-width: 950px) {
    .navbar__link h4{
        font-size: 12px;
    }
    .navbar{
        gap: 1rem;
    }
}

@media screen and (max-width: 780px) {
    .navbar{
        gap: .5rem;
    }
}

@media screen and (max-width: 991px) {
    .navbar{
        display: none;
    }
}

@media screen and (max-width: 1124px) {
    .navbar__link h4{
        font-size: 12px;
        padding: 6px 10px;
    }
}
