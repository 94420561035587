.search{
    flex: 1;
    max-width: 560px;
}
.search__field{
    border: 2px solid var(--primary);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 36px;
}

.search__field input{
    height: unset !important;
    border: none;
    outline: none;
    font-weight: 500;
    color: var(--text);
    padding: 0 8px;
    font-size: 14px;
    background-color: var(--background);
}
.search__field input:focus{
    border: none;
    outline: none;
    padding: 0 8px;
}

.search__field div{
    width: 20px;
    background-color: var(--primary);
}

.search__field button{
    width: 60px;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__field button div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__result{
    position: relative;
    top: 0;
    width: calc(100% - 14px);
}

@media screen and (max-width: 991px) {
    .search{
        display: none;
    }
}