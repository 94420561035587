.payment-options {
    display: flex;
    flex-direction: row;
    gap: 8px 32px;
}

.payment-options>div{
    flex: 1;
}
@media screen and (max-width: 600px) {
    .payment-options {
        flex-direction: column;
    }
}