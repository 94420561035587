.hamburger{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    padding: 7px;
    outline: none;
}

.hamburger__petty{
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background-color: white;
}

