.card{
    padding: 8px;
    border: 1px solid gainsboro;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.103); */
    border-radius: 4px;
    background-color: white;
}

.card.primary{
    background-color: var(--primary);
}

.card.secondary{
    border: 1px solid var(--secondary);
    background-color: #2bcb7334;
}