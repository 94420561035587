.button{
    padding: 8px 16px !important;
    background-color: var(--secondary);
    color: white;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 1px var(--primary);
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid transparent;
    font-weight: 500;
    font-family: 'roboto';
    letter-spacing: 0.5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button:hover{
    box-shadow: 1px 1px 3px 1px var(--primary);
}
.button:active{
    box-shadow: none;
    background-color: var(--primary);
}

.button.warn{
    background-color: var(--warn);
    box-shadow: 2px 2px 3px 1px var(--warn-deep);
}
.button.warn:hover{
    box-shadow: 1px 1px 3px 1px var(--warn-deep);
}
.button.warn:active{
    box-shadow: none;
    background-color: var(--warn-deep);
}


.button.button--outline{
    background-color: white;
    color: var(--secondary);
    box-shadow: none;
    white-space: nowrap;
    border: 1px solid var(--secondary);
}
.button.button--outline:hover{
    background-color: #2bcb7336;
    box-shadow: none;
}
.button.button--outline:active{
    background-color: var(--primary);
    color: white;
}

.button.warn.button--outline{
    background-color: white;
    color: var(--warn);
    border: 1px solid var(--warn);
}
.button.warn.button--outline:hover{
    background-color: #f4433620;
}
.button.warn.button--outline:active{
    background-color: var(--warn);
    color: white;
}



@media all and (max-width:575px){
    .button{
        padding: 5px 5px !important;
        font-size: 12px;
    } 
}