.category{
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 4px 0 4px 10px;
    cursor: pointer;
}

.category__img{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px;
}

.category__img img{
    width: 100%;
    height: 100%;
}

.category__name{
    font-weight: 500;
    position: relative;
    padding-right: 10px;
    border-right: 1px solid var(--secondary);
    cursor: pointer;
}

.category__name h4{
    font-size: 14px;
    color: #686868;
}

.category__name div{
    position: absolute;
    bottom: -4px;
    height: 2px;
    width: 0;
    background-color: var(--primary);
    transition: all 200ms ease-in-out;
}
