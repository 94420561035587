.address>div{
    display: flex;
    justify-content: center;
    gap: 4px;
}

.address>div>*{
    flex: 1;
}

@media screen and (max-width: 620px) {
    .address>div{
        flex-direction: column;
    }
}