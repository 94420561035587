.payment-option{
  border: 1px solid rgb(202, 202, 202);
  border-radius: 4px;
}
.payment-option.active{
  border: 1px solid var(--primary);
}
.payment-option label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  margin: 0;
  background-color: rgba(231, 231, 231, 0.5);
  padding: 8px;
  cursor: pointer;
}
.payment-option.active label{
  background-color: var(--secondary);
}

.payment-option input {
  width: unset;
  margin: 0;
  width: 16px;
}


@media all and (max-width:767px){
  .payment-option label{
    font-size: 14px !important;
  }
}