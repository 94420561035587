.categories{
    height: 36px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.categories button{
    height: 36px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    background-color: var(--secondary);
    width: 220px;
    padding: 4px 16px;
    text-align: left;
}

.categories__content{
    height: 0;
    overflow: hidden;
}
.categories:hover .categories__content{
    height: auto;
    border: 1px solid gainsboro;
    border-radius: 0 0 4px 4px;
    box-shadow: 1px 2px 4px 2px rgb(0 0 0 / 40%);
}